<template>
	<div class="flex relative sm:flex-row h-screen sm:h-auto flex-col items-top bg-white sm:rounded-3xl shadow-default sm:w-2/3 min-w-min h-max sm:p-12 p-0">
		<div class="flex sm:flex-col items-center sm:w-5/12 sm:p-5 sm:bg-white bg-gray-100 py-5 px-4">
			<div class="w-2/12 sm:w-auto">
				<img src="@/assets/img/logo.png" alt="Логотип" width="158" height="173" class="max-w" />
			</div>
			<p class="sm:pt-10 w-10/12 sm:max-w font-bold text-center uppercase sm:normal-case sm:text-sm text-xs">
				Портал независимой оценки качества условий оказания услуг медицинскими организациями
			</p>
		</div>

		<span class="sm:flex hidden sm:show h-screen max-h-96 w-px min-w-px mx-4 my-auto bg-gray-100"></span>

		<form class="flex relative sm:static flex-col justify-between h-full sm:h-auto sm:w-7/12 md:p-5 md:pr-16 p-4 mt-14">
			<div class="flex absolute right-8 -top-8 sm:top-8 h-8">
				<div class="flex flex-row items-center mr-6 h-8 text-sm text-blue-500" id="specialButton" style="cursor: pointer" alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ">
					<span class="text-3xl mr-1">&#128065;</span>
					<p>версия для слабовидящих</p>
				</div>
				<a class="flex flex-row items-center text-sm text-blue-500" href="" @click.prevent="isModalVisible = true">
					<help class="mr-2" />
					Помощь
				</a>
			</div>
			<div class="">
				<h2 class="text-3xl font-bold">Авторизация</h2>
				<label class="flex flex-col mt-16 md:w-4/5">
					<p class="flex text-sm">Логин</p>
					<input
						class="flex mt-2 p-4 text-sm text-gray-600 border outline-none border-solid border-gray-400 hover:border-blue-500 focus:border-blue-500 focus:bg-white rounded-xl bg-white"
						type="username"
						:disabled="loadingBtn"
						placeholder="Введите логин"
						@change="err = []"
						v-model="userData.username"
					/>
					<p class="flex text-red-400 text-sm" v-if="err.find((err) => err.field === 'username')">{{ err.find((err) => err.field === 'username').text }}</p>
				</label>

				<label class="flex flex-col mt-5 relative md:w-4/5">
					<p class="flex text-sm">Пароль</p>
					<input
						class="flex mt-2 p-4 text-sm text-gray-600 border outline-none border-solid border-gray-400 hover:border-blue-500 focus:border-blue-500 focus:bg-white rounded-xl bg-white"
						:type="passwordInputType"
						autocomplete="on"
						:disabled="loadingBtn"
						placeholder="Введите пароль"
						@change="err = []"
						v-model="userData.password"
					/>
					<span class="flex w-6 h-4 ml-auto mr-4 items-center absolute right-0 top-12 bg-no-repeat bg-center select-none" @click.prevent="showPassword = !showPassword">
						<open v-if="showPassword" />
						<close v-else />
					</span>
				</label>
				<p class="flex text-red-400 text-sm" v-if="err.find((err) => err.field === 'password')">{{ err.find((err) => err.field === 'password').text }}</p>
			</div>
			<a class="mt-2 text-blue-500 underline cursor-pointer" @click="open = true">Забыли пароль?</a>
			<button
				class="flex justify-center items-center sm:w-4/5 mt-8 p-5 text-sm bg-blue-500 outline-none border border-blue-500 hover:bg-white focus:bg-white hover:text-blue-500 focus:text-blue-500 text-white rounded-xl pos"
				type="submit"
				@click.prevent="submitUserData('token')"
			>
				<loading v-if="loadingBtn" />
				<span v-else>Войти</span>
			</button>
		</form>
		<div v-if="isModalVisible" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
			<div class="relative sm:w-4/12 my-6 mx-auto max-w-6xl">
				<!--content-->
				<div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
					<!--header-->
					<div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t-lg bg-blue-50">
						<h3 class="text-xl font-semibold">Помощь</h3>
						<span class="cursor-pointer text-blue-500" @click="isModalVisible = false">
							<Crose />
						</span>
					</div>
					<!--body-->
					<div class="relative p-6 flex-auto">
						<p><a class="text-blue-500 underline" href="https://nok.minzdrav.gov.ru/docs/НОК_Ролевая_модель.pdf" target="_blank" rel="noopener noreferrer"> Ролевая модель </a></p>
						<p class="mt-4">
							<a class="text-blue-500 underline" href="https://nok.minzdrav.gov.ru/Files/НОК_Руководство пользователя.pdf" target="_blank" rel="noopener noreferrer">
								Руководство пользователя
							</a>
						</p>
						<p class="mt-8 my-4 text-blueGray-500 text-lg leading-relaxed">Служба технической поддержки:</p>
						<p class="my-4 text-gray-500 text-md leading-relaxed">Часы работы: 09:00-18:00 (МСК)</p>
						<p class="my-4 text-gray-500 text-md leading-relaxed">8 (495) 627-29-61 | +7 (901) 756-94-65</p>
						<p class="my-4 text-gray-500 text-md leading-relaxed">nok-support@rosminzdrav.ru</p>
					</div>
				</div>
			</div>
		</div>

		<div v-if="isModalVisible" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
		<PopUp :open="open" size="441px" :title="popupTitle" @close="closePopup()">
			<div v-if="step === 'login'">
				<TextInput label="Введите свой логин" :error="loginErr" v-model="userChangePassword.login" />
			</div>
			<div v-else>
				<label class="label" for="code">Введите код</label>
				<div class="flex justify-between" :class="{ error: codeErr }">
					<div class="input-box">
						<input type="text" ref="code1" maxlength="1" autocomplete="off" id="code" v-model="code[0]" @input="codeChange(userChangePassword.code, 'code2')" />
					</div>
					<div class="input-box">
						<input type="text" ref="code2" maxlength="1" autocomplete="off" v-model="code[1]" @input="codeChange(userChangePassword.code, 'code3')" />
					</div>
					<div class="input-box">
						<input type="text" ref="code3" maxlength="1" autocomplete="off" v-model="code[2]" @input="codeChange(userChangePassword.code, 'code4')" />
					</div>
					<div class="input-box">
						<input type="text" ref="code4" maxlength="1" autocomplete="off" v-model="code[3]" @input="codeChange(userChangePassword.code, null)" />
					</div>
				</div>
				<p class="text-red-400 text-sm" v-if="codeErr">Неверный код</p>
				<div class="flex flex-col relative">
					<TextInput
						class="mt-6"
						@input="confirmPass(userChangePassword)"
						label="Новый пароль"
						:type="!showPassword ? 'password' : 'text'"
						autocomplete="new-password"
						v-model="userChangePassword.pass1"
					/>
					<span class="flex w-6 h-4 ml-auto mr-4 items-center absolute right-0 bottom-9 bg-no-repeat bg-center select-none" @click.prevent="showPassword = !showPassword">
						<open v-if="showPassword" />
						<close v-else />
					</span>
				</div>
				<TextInput
					@input="confirmPass(userChangePassword)"
					label="Повторите пароль"
					autocomplete="new-password"
					:error="passErr"
					:type="!showPassword ? 'password' : 'text'"
					v-model="userChangePassword.pass2"
				/>
			</div>
			<div class="flex mt-5 justify-between">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closePopup()" />
				<Button size="medium" text="Отправить" :load="loadingChangePassword" @click.native="submitCode()" />
			</div>
		</PopUp>
		<PopUp :open="openConfirmCode" size="441px" title="Подтвердите учетную запись" @close="closeConfirmCode()">
			<div v-if="confirmCode">
				<label class="label" for="code">Введите код</label>
				<div class="flex justify-between" :class="{ error: codeErr2 }">
					<div class="input-box">
						<input type="text" ref="code5" maxlength="1" autocomplete="off" id="code" v-model="code[0]" @input="codeChange(userConfirmCode.code, 'code6')" />
					</div>
					<div class="input-box">
						<input type="text" ref="code6" maxlength="1" autocomplete="off" v-model="code[1]" @input="codeChange(userConfirmCode.code, 'code7')" />
					</div>
					<div class="input-box">
						<input type="text" ref="code7" maxlength="1" autocomplete="off" v-model="code[2]" @input="codeChange(userConfirmCode.code, 'code8')" />
					</div>
					<div class="input-box">
						<input type="text" ref="code8" maxlength="1" autocomplete="off" v-model="code[3]" @input="codeChange(userConfirmCode.code, null)" />
					</div>
				</div>
				<p class="text-red-400 text-sm" v-if="codeErr2">Неверный код</p>
			</div>
			<div v-else>
				<TextInput class="mt-6" @input="confirmPass(userConfirmCode)" label="Новый пароль" type="password" autocomplete="new-password" v-model="userConfirmCode.pass1" />
				<TextInput @input="confirmPass(userConfirmCode)" label="Повторите пароль" autocomplete="new-password" :error="passErr" type="password" v-model="userConfirmCode.pass2" />
			</div>
			<div class="mt-5">
				<Button size="full" v-if="confirmCode" :disabled="disabledButton" class="mb-3" :text="textButton" @click.native="getNewCode(userData.username)" />
				<Button size="full" :text="confirmCode ? 'Отправить' : 'Поменять пароль'" :load="loadingConfirmCode" @click.native="submitConfirmedCode()" />
			</div>
		</PopUp>
		<div class="confirm" v-if="confirmPassPopUp">Пароль изменен!</div>
	</div>
</template>

<script>
// Глобальные данные стора
import { actions, mutations } from '@/store/store';
import { localActions } from '../store/actions';
import help from '../../../assets/img/help.svg';
import open from '../../../assets/img/eye-opened.svg';
import close from '../../../assets/img/eye-closed.svg';
import Crose from '@/assets/img/close.svg';
import Button from '@/components/Button.vue';
import PopUp from '@/components/PopUp.vue';
import TextInput from '@/components/TextInput.vue';
import loading from '../../../components/Loading.vue';
import uhpv from '@/plugins/uhpv-full.min';

const PasswordLength = 6;

export default {
	name: 'Login',
	components: {
		loading,
		help,
		Button,
		PopUp,
		TextInput,
		open,
		Crose,
		close
	},
	data() {
		return {
			isModalVisible: false,
			loadingBtn: false,
			loadingChangePassword: false,
			loadingConfirmCode: false,
			showPassword: false,
			userData: {
				username: '',
				password: ''
			},
			textButton: 'Получить код повторно',
			confirmCode: true,
			code: [],
			popupTitle: 'Смена пароля',
			open: false,
			userChangePassword: {
				code: '',
				login: '',
				pass1: '',
				pass2: ''
			},
			disabledButton: false,
			err: [],
			userConfirmCode: {
				login: null,
				code: null,
				pass1: null,
				pass2: null
			},
			confirmPassPopUp: false,
			disabled: false,
			count: 0,
			step: 'login',
			codeErr: null,
			loginErr: null,
			codeErr2: null,
			openConfirmCode: false,
			passErr: null
		};
	},
	computed: {
		passwordInputType() {
			return this.showPassword ? 'text' : 'password';
		}
	},
	methods: {
		...actions,
		...mutations,
		...localActions,
		closePopup() {
			this.open = false;
			this.step = 'login';
			this.code = [];
			this.userChangePassword = {
				code: '',
				login: '',
				pass1: '',
				pass2: ''
			};
		},
		getNewCode(username) {
			this.reSendEmailConfirmedCode(username).then(() => {
				this.count = 30;
				let timerId = setInterval(() => {
					this.count -= 1;
					this.disabledButton = true;
					this.textButton = `Получить код повторно через ${this.count}`;
					if (this.count === 0) {
						this.disabledButton = false;
						this.textButton = 'Получить код повторно';
						clearInterval(timerId);
					}
				}, 1000);
			});
		},
		submitConfirmedCode() {
			if (this.confirmCode) {
				this.loadingConfirmCode = true;
				this.checkEmailConfirmedCode(this.userConfirmCode)
					.then(() => {
						this.confirmCode = false;
						this.codeErr2 = null;
						this.loadingConfirmCode = false;
					})
					.catch(() => {
						this.codeErr2 = 'Неверный код';
						this.loadingConfirmCode = false;
					});
			} else {
				if (this.userConfirmCode.pass1.length < PasswordLength) {
					this.passErr = 'Пароль должен содержать не менее 6 символов';
				} else if (this.userConfirmCode.pass1 !== this.userConfirmCode.pass2) {
					this.passErr = 'Пароли не совпадают';
				} else {
					this.loadingConfirmCode = true;
					this.passErr = null;
					this.setPassword(this.userConfirmCode).then(() => {
						this.closeConfirmCode();
						this.confirmCode = false;
						this.loadingConfirmCode = false;
						this.confirmPassPopUp = true;
						setTimeout(() => {
							this.confirmPassPopUp = false;
						}, 2000);
					});
				}
			}
		},
		closeConfirmCode() {
			this.userConfirmCode = {
				code: null,
				id: null,
				pass1: null,
				pass2: null
			};
			this.code = [];
			this.confirmCode = true;
			this.openConfirmCode = false;
		},
		codeChange(item, code) {
			if (code) {
				this.$refs[code].focus();
			}
			this.userConfirmCode.code = this.code.join('');
			this.userChangePassword.code = this.code.join('');
		},
		confirmPass(form) {
			if (form.pass1.length < PasswordLength) {
				this.passErr = 'Пароль должен содержать не менее 6 символов';
				this.disabled = true;
			} else if (form.pass1 !== form.pass2) {
				this.passErr = 'Пароли не совпадают';
				this.disabled = true;
			} else {
				this.passErr = null;
				this.disabled = false;
			}
		},
		submitCode() {
			if (this.step === 'login') {
				this.loadingChangePassword = true;
				this.loginErr = null;
				this.getCode(this.userChangePassword.login)
					.then(() => {
						this.step = 'code';
						this.loadingChangePassword = false;
					})
					.catch(() => {
						this.loginErr = 'Неверный логин';
						this.loadingChangePassword = false;
					});
			} else {
				this.loadingChangePassword = true;
				this.recoverPassword(this.userChangePassword)
					.then(() => {
						this.step = 'login';
						this.codeErr = null;
						this.confirmPassPopUp = true;
						this.loadingChangePassword = false;
						this.closePopup();
						setTimeout(() => {
							this.confirmPassPopUp = false;
						}, 2000);
					})
					.catch(() => {
						this.codeErr = 'Неверный код';
						this.loadingChangePassword = false;
					});
			}
		},
		submitUserData(url, hash) {
			if (!hash && !this.validateUserData()) return;
			this.loadingBtn = true;
			let formData = new FormData();
			formData.append('hash', hash);
			this.$http.post(`User/${url}${url === 'token' ? `?username=${this.userData.username}&password=${this.userData.password}` : ''}`, url === 'token' ? {} : { hash: hash }).then(
				(res) => {
					this.$http
						.get('User/GetUserInfo', {
							headers: {
								Authorization: `Bearer ${res.data.access_token}`
							}
						})
						.then((response) => {
							this.saveGlobalUser({
								...response.data,
								access_token: res.data.access_token
							});
							let role = response.data.roles;

							if (response.data.emailConfirmed) {
								// ЛК Членов ОС
								if (role.indexOf('OS_Secretary') === 0 || role.indexOf('OS_Chairman') === 0 || role.indexOf('OS_Member') === 0) {
									if (role.indexOf('OS_Member') !== -1) {
										this.$router.push('/osmember/controlEvent');
									} else {
										this.$router.push('/osmember/activities');
									}
								}

								// ЛК Оператор
								if (role.indexOf('OGV_PaperOperator') === 0) {
									this.$router.push(`/questionnaire/ambulator/${response.data.regionOldId}`);
								}

								// ЛК ОГВ
								if (role.indexOf('OGV_Curator') === 0 || role.indexOf('OGV_Observer') === 0) {
									this.$router.push('/ogv/general');
								}

								// ЛК Минздрава
								if (role.indexOf('MZ_Employee') === 0 || role.indexOf('MZ_Observer') === 0 || role.indexOf('Support') === 0) {
									this.$router.push('/minzdrav/general');
								}
							} else {
								this.userConfirmCode.id = response.data.id;
								this.userConfirmCode.login = response.data.login;
								this.openConfirmCode = true;
							}

							this.loadingBtn = false;
						});
				},
				(err) => {
					if (err.request.status === 500 || err.request.status === 404) {
						this.err.push({ field: 'password', text: 'Пользователь не найден в системе' });
						localStorage.removeItem('hash');
						// this.$nextTick(() => {
						// 	window.location.href = 'https://nok.minzdrav.gov.ru/home/logout';
						// });
					} else {
						this.err.push({ field: 'password', text: `Ошибка ${err.request.status}` });
						localStorage.removeItem('hash');
						// this.$nextTick(() => {
						// 	window.location.href = 'https://nok.minzdrav.gov.ru/home/logout';
						// });
					}
					this.loadingBtn = false;
				}
			);
		},
		validateUserData() {
			this.err = [];
			if (!this.userData.username) {
				this.err.push({ field: 'username', text: 'Введите логин' });
				return false;
			}
			if (!this.userData.password) {
				this.err.push({ field: 'password', text: 'Введите пароль' });
				return false;
			}
			return true;
		}
	},
	created() {
		uhpv();
		if (localStorage.getItem('hash')) {
			localStorage.removeItem('hash');
			this.loadingBtn = true;
			this.$nextTick(() => {
				this.loadingBtn = false;
				window.location.href = 'https://nok.minzdrav.gov.ru/home/logout';
			});
		}

		if (this.$route.hash) {
			this.loadingBtn = true;
			localStorage.setItem('hash', true);
			let idx = this.$route.hash.indexOf('=');
			this.submitUserData('RedirectLogin', this.$route.hash.substring(idx + 1));
		}
	}
};
</script>

<style scoped>
.confirm {
	position: fixed;
	right: 20px;
	top: 20px;
	background: green;
	color: #fff;
	padding: 10px 20px;
	border-radius: 10px;
	font-size: 18px;
}

.input-box input {
	width: 47px;
	height: 49px;
	text-align: center;
	border: 1px solid #a8b1ce;
	border-radius: 10px;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	color: #6a6e83;
}

.input-box input:focus {
	outline: none;
}

.error .input-box input {
	border-color: red;
}

.label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}
</style>
